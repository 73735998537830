import { StateInterface } from "@/store/index";
import { computed } from "vue";
import { useStore } from "vuex";

export const useCentersSelectors = () => {
  const store = useStore<StateInterface>();

  return {
    // State
    referenceCenters: computed(
      () => store.state.centersSelectors.referenceCenters
    ),
    referenceCenterSelected: computed(
      () => store.state.centersSelectors.referenceCenterSelected
    ),
    // careCenters: computed(() =>
    //   store.state.centersSelectors.referenceCenterSelected
    //     ? store.state.centersSelectors.referenceCenterSelected.
    //     : []
    // ),
    careCenterSelected: computed(
      () => store.state.centersSelectors.careCenterSelected
    ),

    // Getters
    _getReferenceCenterSelected: computed(
      () => store.getters["centersSelectors/getReferenceCenterSelected"]
    ),
    _getCareCenterSelected: computed(
      () => store.getters["centersSelectors/getCareCenterSelected"]
    ),

    // Actions
    _getCenters: () => store.dispatch("centersSelectors/getCenters"),

    /**
     * @ ROLES
     * 99 - SuperAdmin
     * 2 - Adm. Centro Referencia
     * 3 - Adm. Centro Atencion
     * 5 - Medico / Medicina Interna
     * 6 - Enfermeria
     * 7 - Paciente
     */

    /**
     * 3 - Adm. Centro Atencion
     * 6 - Enfermeria
     */
    _disableCareCenter: (user_type_id: number) =>
      [3, 6, 7].includes(user_type_id),

    /**
     * 2 - Adm. Centro Referencia
     * 3 - Adm. Centro Atencion
     * 5 - Medico / Medicina Interna
     * 6 - Enfermeria
     */
    _disableReferenceCenter: (user_type_id: number) =>
      [2, 3, 5, 6, 7].includes(user_type_id),

    // Mutation
    _setReferenceCenter: (rc: object | number | string | null) =>
      store.commit("centersSelectors/setReferenceCenter", rc),
    _setCareCenter: (cc: object | number | string | null) =>
      store.commit("centersSelectors/setCareCenter", cc),
  };
};
