import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/agendamiento",
    name: "SchedulesList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "SchedulesListView" */ "@/views/schedules/schedules-list/SchedulesList.vue"
      ),
  },

  {
    path: "/agendameinto/crear-agendamiento",
    name: "SchedulesCreateView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "SchedulesCreateView" */ "@/views/schedules/schedules-create/SchedulesCreateView.vue"
      ),
  },
];
