import { isAuthenticatedGuard } from "@/router/auth-guard";

export default[
    
    {
        path: '/motivos-cancelacion',
        name: 'CancellationReason',
        beforeEnter: [ isAuthenticatedGuard ],
        component: () => import('@/views/cancellation-reasons/CancellationReason.vue'),
    },
    /* Create reasons */
    {
        path: '/crear-motivo',
        name: 'CreateReason',
        beforeEnter: [ isAuthenticatedGuard ],
        component: () => import('@/views/cancellation-reasons/subpages/create-reason/CreateReason.vue')
    }
]