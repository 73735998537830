import { ActionTree } from "vuex";
import { TeleexpertisesState, Response } from "./state";
import { StateInterface } from "../index";
import {
  teleexpertisesApi,
  telexpertiseApiV2,
  teleexpertisesApiEntitys,
  teleexpertisesApiRespond,
  teleexpertisesResourcesApi,
} from "@/apis";
import { IError } from "@/interfaces/customs/interface";
import { ITelexperticeSchedule } from "@/interfaces/global/Telexpertise";

const headers = {
  "Content-Type": "multipart/form-data",
};

const actions: ActionTree<TeleexpertisesState, StateInterface> = {
  async getTeleexpertises({ commit }, { page, status }): Promise<Response> {
    try {
      const { data } = await teleexpertisesApi().get(`${status}?page=${page}`);
      const { success, message, data: teleexpertiseData } = data;
      commit("getTeleexpertises", teleexpertiseData);
      return { success, message };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async getListTelexpertiseV2(
    { commit },
    { page, filters }
  ): Promise<Response> {
    try {
      const { data } = await telexpertiseApiV2(filters, page).get(``);
      const { success, message, data: teleexpertiseData } = data;
      commit("getTeleexpertises", teleexpertiseData);
      return { success, message };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async changeTeleexpertiseStatusById(
    { commit },
    { token, id }
  ): Promise<Response> {
    try {
      const { data } = await teleexpertisesApi().post(
        `/changeStatus/${id}`,
        {}
      );
      const { success, message } = data;

      return { success, message };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async calculateScale({ commit }, { token, formData }): Promise<Response> {
    try {
      const { data } = await teleexpertisesApi().post(`/scale/calculate`, {
        ...formData,
      });
      const { success, message, data: datum } = data;
      // @ts-ignore
      return { success, message, datum };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async getOptionsScales(
    { commit },
    { token, filterScales }
  ): Promise<Response> {
    try {
      const { data } = await teleexpertisesApi().get(
        filterScales ? `/scale${filterScales}` : `/scale`
      );
      const { success, message, data: datum } = data;
      commit("setScalesOptions", datum);
      // @ts-ignore
      return { success, message, datum };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async createTeleexpertise(
    { commit },
    {
      token,
      bodyRequest,
      medicalExamsDocuments,
      clinicalLaboratoriesDocuments,
      documents,
      signature,
    }
  ): Promise<Response> {
    try {
      const { data } = await teleexpertisesApi().post(
        "/",
        {
          data: bodyRequest,
          medical_exams: medicalExamsDocuments,
          clinical_laboratories: clinicalLaboratoriesDocuments,
          documents,
          signature: signature,
        },
        { headers }
      );
      const { success, message } = data;

      return { success, message };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async responseTeleexpertise(
    { commit },
    { token, bodyRequest, documents }
  ): Promise<Response> {
    try {
      const { data } = await teleexpertisesApiRespond().post(
        "/responseTeleexpertise",
        {
          data: bodyRequest,
          documents,
        },
        { headers }
      );
      const { success, message } = data;

      return { success, message };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async getTeleexpertiseById({ commit }, { token, id }) {
    try {
      const { data } = await teleexpertisesApi().get(`/getTeleexpertise/${id}`);
      const { success, message, data: teleexpertiseData } = data;

      const telexperticeScheduleRef: ITelexperticeSchedule = teleexpertiseData;

      commit("setTeleexpertiseById", teleexpertiseData);

      if (telexperticeScheduleRef.currentSchedule !== null) {
        const teleExperticeInfo = {
          id: telexperticeScheduleRef.currentSchedule.scheduleable_id,
          initialHour: telexperticeScheduleRef.currentSchedule.scheduled_at,
          finalHour:
            telexperticeScheduleRef.currentSchedule.schedule_final_hour,
          meetURL: telexperticeScheduleRef.currentSchedule.schedule_url,
          status: telexperticeScheduleRef.currentSchedule.status,
          token: telexperticeScheduleRef.currentSchedule.token,
          patient: `${telexperticeScheduleRef.patient.name} ${telexperticeScheduleRef.patient.last_name}`,
          formatedDay:
            telexperticeScheduleRef.currentSchedule.scheduled_at_formatted,
          type: telexperticeScheduleRef.currentSchedule
            .scheduleable_type_formatted,
        };

        commit("setTeleexperticeSchedule", teleExperticeInfo);
      } else {
        const teleExperticeInfo = {
          id: -1,
          initialHour: "",
          finalHour: "",
          meetURL: "",
          status: "NotAvailable",
          token: "",
          patient: `${telexperticeScheduleRef.patient.name} ${telexperticeScheduleRef.patient.last_name}`,
          formatedDay: "",
          type: "",
        };

        commit("setTeleexperticeSchedule", teleExperticeInfo);
      }

      return { success, message, teleexpertiseData };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },

  async updateTeleexpertise(
    _,
    { token, id, code, description }
  ): Promise<Response> {
    const newBodyRequest = { id, code, description };

    try {
      const { data } = await teleexpertisesApi().post("/", newBodyRequest);
      const { success, message } = data;

      return { success, message };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async getAllResources({ commit }, { filters }) {
    try {
      const { data } = await teleexpertisesResourcesApi().get(
        filters ? `/utils/getAllResources${filters}` : `/utils/getAllResources`
      );

      const { success, message, data: resourcesAll } = data;
      commit("setResources", resourcesAll);
      return { success, message, resourcesAll };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async getBackgroundHistory({ commit }, { token, patientID, page = 1 }) {
    try {
      const { data } = await teleexpertisesResourcesApi().get(
        `/utils/getBackgrounds/${patientID}?page=${page}`
      );

      const { success, message, data: backgroundsHistory } = data;

      backgroundsHistory.data.forEach((element: any, i: number) => {
        element["reference_center_name"] =
          element?.patient?.center_attention?.center_reference?.name;
        element["type_background"] = element.background?.name;
        element[
          "cie"
        ] = `${element.diagnostic?.code} - ${element.diagnostic?.description}`;
      });

      commit("setBackgroundHistory", backgroundsHistory);

      return { success, message, backgroundsHistory };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async getClinicalLaboratoryHistory(
    { commit },
    { token, patientID, page = 1 }
  ) {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await teleexpertisesResourcesApi().get(
        `/utils/getClinicalLaboratories/${patientID}?page=${page}`,
        config
      );

      const { success, message, data: clinicalLaboratoriesHistory } = data;

      clinicalLaboratoriesHistory.data.forEach((element: any, i: number) => {
        element["laboratory"] = element.procedure?.description;
        element["classification_object"] = element.classification;
        element["classification"] = element.classification?.name;
      });

      commit("setClinicalLaboratoryHistory", clinicalLaboratoriesHistory);

      return { success, message, clinicalLaboratoriesHistory };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async getMedicalExamsHistory({ commit }, { token, patientID, page = 1 }) {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await teleexpertisesResourcesApi().get(
        `/utils/getMedicalExams/${patientID}?page=${page}`,
        config
      );

      const { success, message, data: medicalExamsHistory } = data;

      medicalExamsHistory.data.forEach((element: any, i: number) => {
        element["exam"] = element.procedure.description;
        element["classification_object"] = element.classification;
        element["classification"] = element.classification?.name;
      });

      commit("setMedicalExamsHistory", medicalExamsHistory);

      return { success, message, medicalExamsHistory };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async getDiagnosticHistory(
    { commit },
    { token, patientID, page = 1, filter = null }
  ) {
    try {
      const { data } = await teleexpertisesResourcesApi().get(
        filter
          ? `/utils/getDiagnostics/${patientID}?page=${page}?${filter}`
          : `/utils/getDiagnostics/${patientID}?page=${page}`
      );

      const { success, message, data: diagnosticHistory } = data;
      diagnosticHistory.data.forEach((element: any, i: number) => {
        element["reference_center_name"] = element?.reference_center?.name;
        element[
          "diagnostic_description"
        ] = `${element?.diagnostic?.code} - ${element?.diagnostic?.description}`;
        element["created_at"] = element.created_at_formatted;
        element["code"] = element.diagnostic?.code;
      });

      commit("setDiagnosticHistory", diagnosticHistory);

      return { success, message, diagnosticHistory };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async getVaccinesHistory({ commit }, { patientID, page = 1 }) {
    try {
      const { data } = await teleexpertisesResourcesApi().get(
        `/utils/getVaccines/${patientID}?page=${page}`
      );

      const { success, message, data: vaccineHistory } = data;
      vaccineHistory.data.forEach((element: any, i: number) => {
        element["vaccine_name"] = element?.vaccine?.name;
        element["number_of_doses"] =
          element?.type_doses_vaccine?.type_dose?.name;
      });

      commit("setVaccineHistory", vaccineHistory);

      return { success, message };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async getMedicineHistory({ commit }, { patientID, page = 1 }) {
    try {
      const { data } = await teleexpertisesResourcesApi().get(
        `/utils/getMedicines/${patientID}?page=${page}`
      );

      const { success, message, data: medicineHistory } = data;
      commit("setMedicineHistory", medicineHistory);

      return { success, message };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async getProcedureHistory({ commit }, { patientID, page = 1 }) {
    try {
      const { data } = await teleexpertisesResourcesApi().get(
        `/utils/getProcedures/${patientID}?page=${page}`
      );

      const { success, message, data: procedureHistory } = data;
      commit("setProcedureHistory", procedureHistory);

      return { success, message };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async getTelexperticeUtilEntityByIdPatient(
    { commit },
    { token, patientID, entity, page = 1 }
  ) {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await teleexpertisesApi().get(
        `/utils/${entity}/${patientID}?page=${page}`,
        config
      );

      const { success, message, data: dataInfo } = data;

      switch (entity) {
        case "getMedicines":
          commit("setDataTableHistoryMedicinesPatient", dataInfo);
          break;

        case "getProcedures":
          commit("setDataTableHistoryProceduresPatient", dataInfo);
          break;

        default:
          break;
      }

      return { success, message, dataInfo };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },

  // Telexpertises Respond action

  async getEntityRespondTelexpertise({ commit }, { token, entity, id, page }) {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await teleexpertisesApiEntitys().get(
        `/${entity}/${id}?page=${page}`,
        config
      );

      const { success, message, data: resourcesAll } = data;

      switch (entity) {
        case "getVaccines":
          commit("setDataTableVaccination", resourcesAll);
          break;

        case "getMedicalExams":
          commit("setDataTableMedicalExams", resourcesAll);
          break;

        case "getBackgrounds":
          commit("setDataTableBackground", resourcesAll);
          break;

        case "getClinicalLaboratories":
          commit("setClinicalLaboratory", resourcesAll);
          break;

        case "getDocuments":
          commit("setDocuments", resourcesAll);
          break;

        case "getDiagnostics":
          commit("setDataTableDiagnostic", resourcesAll);
          break;

        default:
          break;
      }

      return { success, message, resourcesAll };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async getTelexpertisesByPatient({ commit }, { id, type }): Promise<Response> {
    try {
      const { data } = await teleexpertisesApi().get(
        `/getListbyPatient/${id}`,
        { params: { type } }
      );
      const { success, message, data: teleexpertiseData } = data;

      commit("getTelexpertisesByPatient", teleexpertiseData);
      return { success, message };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },

  async getQuestions({ commit }) {
    try {
      const { data } = await teleexpertisesApi().get(
        `/qualification/questions`
      );
      const { success, message, data: questionsQualification } = data;

      return { success, message, questionsQualification };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async setQualification({ commit }, { dataRequest, telexpertiseId }) {
    try {
      const { data } = await teleexpertisesApi().post(
        `/qualification/${telexpertiseId}/qualify`,
        dataRequest
      );
      const { success, message, data: qualify } = data;

      return { success, message, qualify };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
};

function errorException(error: IError) {
  const success = false;
  if (error.message == "Network Error") {
    const { message } = error;
    return { success, message };
  }
  const data = error.response?.data;
  if (data) {
    const { success, message } = data;
    return { success, message };
  }
  const message = "Error interno";
  return { success, message };
}

export default actions;
