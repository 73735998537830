import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import { executeApi } from "@/apis";
import errorException from "@/functions/ErrorException";
import { IError } from "@/interfaces/customs/interface";
import { IPatientBySchedule } from "@/interfaces/global/Patient";
import { ResourcesState } from "@/interfaces/global/interfaces";

const headers = {
  "Content-Type": "multipart/form-data",
};

const URL_PATH_UTILS = "/api/settings/utils";

const URL_PATH_RESOURCES = "/api/settings/utils/resources";

const URL_PATH_RESOURCES_PATIENT = "/api/patients/utils/getAllResource";

const URL_PATH_DOCUMENTTYPE_ADMINISTRATORS = "/api/patients/documentTypes";

const actions: ActionTree<ResourcesState, StateInterface> = {
  async getResources(
    { commit },
    { query, centers, userType }
  ): Promise<object> {
    try {
      let center = "";

      switch (userType) {
        case 2: //Admin Ref Centers
        case 5: //Medical
        case 99:
          center = `&care_center_id=${centers.care_center}`;
          break;
      }
      const { data } = await executeApi().get(
        query
          ? `${URL_PATH_RESOURCES}${query}${center}`
          : `${URL_PATH_RESOURCES}`
      );

      const { success, message } = data;

      //   commit("setResources", data.data);

      return { success, message };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async getResourcesPhysicalExam(
    { }): Promise<object> {
    try {
      const { data } = await executeApi().get(`${URL_PATH_UTILS}/physical-exams`);
      return data;
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async getDocumentTypesAdministrators(
    { commit },
    documentTypes
  ): Promise<object> {
    try {
      let documentTypesSplit = documentTypes.split(",");
      let query = documentTypesSplit
        .map(
          (elemento: string, index: number) =>
            `abbreviations[${index}]=${elemento}`
        )
        .join("&");

      const { data } = await executeApi().get(
        `${URL_PATH_DOCUMENTTYPE_ADMINISTRATORS}?${query}`
      );

      const { success, message } = data;

      commit("setDocumentTypes", data.data);
      return { success, message };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
};

export default actions;
