import { createStore } from "vuex";

// Module Imports
import generalStoreModule from "./generalStore";
import surveysModule from "./surveys";
import authModule from "./auth";
import availableModule from "./available";
import centersModule from "./centers";
import usersModule from "./users";
import medicinesModule from "./medicines";
import contractsModule from "./contracts";
import proceduresModule from "./procedures";
import specialtesModule from "./specialtes";
import atentionCentersModule from "./atentioncenter";
import patientsModule from "./patients";
import attendanceModule from "./patients/attendance";
import diagnosticsModule from "./diagnostics";
import referenceCentersModule from "./reference-centers";
import teleexpertisesModule from "./teleexpertises";
import schedulesModule from "./schedules";
import medicalRecordsModule from "./medical-records";
import healthcareManagementModule from "./healthcareManagement";
import jobListModule from "./healthcareManagement/jobList";
import foliosModule from "./folios";
import careProgramsModule from "./care-programs";
import campaignsModule from "./campaigns";
import disabilitiesModules from "./patients/disability";
import ripsModule from "./RIPS";
import unificationMedicalRecordsModule from "./unification-medical-records";
import explanatoryNotesModule from "./explanatory-notes";
import cdasModule from "./cdas";
import nursingNotesModule from "./nursingNotes";
import scheduleReportsModule from "./schedule-reports";
import telesupportsModule from "./telesupport";
import indicatorsModule from "./indicators";
import liquidControlModule from "./liquidControl";
import selectorsModule from "./selectors";
import resolutionReportsModule from "./resolution-reports";
import oxygenControlModule from "./oxygenControl";
import triageModule from "./triage";
import initialAttentionsModule from "./initial-attentions";
import emergenciesModule from "./emergencies";
import indicators256Module from "./indicators-256";
import telemedicineModule from "./telemedicine";
import highCostAccounts2463Module from "./high-cost-accounts-2463";
import appointmentConfirmationModule from "./appointment-confirmation";
import resolution4725Module from "./resolution-4725";
import absencesModule from "./absences";
import proceduresReportModule from "./procedures-report";
import medicinesReportModule from "./medicines-report";
import controlBoardsModule from "./control-boards";
import resolution1604of2013State from "./resolution-1604-2013";
import notificationsModule from "./notifications";
import medicationAdministratorModule from "./medication-administrator";
import digitalSignatureModule from "./digital-signature";
import timeInactivityModule from "./time-inactivity";
import physicalExamTemplatesModule from "./physical-exam-templates";
import backgroundTemplate from "./background-templates";
import prescriptionProceduresTemplate from "./prescription-procedures-templates";
import prescriptionMedicinesTemplate from "./prescription-medicines-templates";
import supportModule from "./support";
import advanceDirectivesModule from "./advance-directives";
import administratorModule from "./administrators";
import preparationModule from "./preparations";
import certificatesModule from "./certificates";
import packagesModule from "./packages";
import photographModule from "./photograph";
import notesModule from "./notes";
import banksModule from "./banks";
import numerationsModule from "./numerations";
import centersSelectorsModule from "./centers-selectors";
import telemedicineReportModule from "./telemedicine-report";
import advanceDirectiveReportModule from "./advance-directives-report";
import specialtiesModule from "./specialties";
import customComponentsModule from "./custom-components";
import vaccinesModule from "./vaccines";
import resourcesModule from "./resources";
import loginsModule from "./logins";

// UI imports / Interfaces For State
import { GeneralStoreUI } from "./generalStore/state";
import { AuthState } from "./auth/state";
import { AvailableUI } from "./available/state";
import { UsersState } from "./users/state";
import { StateCenters } from "./centers/state";
import { MedicinesState } from "./medicines/state";
import { ProceduresState } from "./procedures/state";
import { DiagnosticsState } from "./diagnostics/state";
import { ContractsUI } from "./contracts/state";
import { SpecialtesState } from "./specialtes/state";
import { AtentionCenterState } from "./atentioncenter/state";
import { PatientsState } from "./patients/state";
import { AttendanceUI } from "./patients/attendance/state";
import { ReferenceCentersState } from "./reference-centers/state";
import { TeleexpertisesState } from "./teleexpertises/state";
import { SchedulesState } from "./schedules/state";
import { MedicalRecordsState } from "./medical-records/state";
import { HealthcareManagementUI } from "./healthcareManagement/state";
import { JobListUI } from "./healthcareManagement/jobList/state";
import { FoliosState } from "./folios/state";
import { CareProgramsState } from "./care-programs/state";
import { CampaignsState } from "./campaigns/state";
import { DisabilityUI } from "./patients/disability/state";
import { RipsUI } from "@/interfaces/customs/IRipsCustom";
import { UnificationMedicalRecordsState } from "./unification-medical-records/state";
import { ExplanatoryNotesState } from "./explanatory-notes/state";
import { CdasState } from "./cdas/state";
import { NursingNotesState } from "./nursingNotes/state";
import { ScheduleReportsState } from "./schedule-reports/state";
import { TelesupportsState } from "./telesupport/state";
import { IndicatorsUI } from "./indicators/state";
import { LiquidControlState } from "./liquidControl/state";
import { SelectorsState } from "./selectors/state";
import { ResolutionReportsState } from "./resolution-reports/state";
import { OxygenControlState } from "./oxygenControl/state";
import { TriageState } from "./triage/state";
import { InitialAttentionsState } from "./initial-attentions/state";
import { EmergenciesState } from "./emergencies/state";
import { Indicators256State } from "./indicators-256/state";
import { TelemedicineState } from "./telemedicine/state";
import { HighCostAccounts2463State } from "./high-cost-accounts-2463/state";
import { AppointmentConfirmationState } from "./appointment-confirmation/state";
import { ResolutionReports4725State } from "./resolution-4725/state";
import { AbsenceState } from "./absences/state";
import {
  ProceduresReportState,
  MedicationAdministratorState,
  AdministratorsState,
  PreparationsState,
  PhotographsState,
  CentersSelectorsState,
  TelemedicineReportState,
  ResourcesState,
  AdvanceDirectiveReportState
} from "@/interfaces/global/interfaces";
import { Resolution2175of2015State } from "./resolution2175of2015/state";
import resolution2175of2015Module from "./resolution2175of2015";
import { MedicinesReportState } from "./medicines-report/state";
import { Resolution1604of2013State } from "./resolution-1604-2013/state";
import { ControlBoardsState } from "./control-boards/state";
import { NotificationsState } from "@/store/notifications/state";
import { ISurveyAbsenceCustom } from "@/interfaces/customs/ISurveysCustom";
import { ISupportCustom } from "@/interfaces/customs/ISupportCustom";
import { AdvanceDirectivesState } from "./advance-directives/state";
import { ICertificateStateCustom } from "@/interfaces/customs/ICertificatesCustom";
import { PackagesState } from "./packages/state";
import { NotesState } from "./notes/state";
import { BanksState } from "./banks/state";
import { NumerationsState } from "./numerations/state";
import { SpecialtiesState } from "./specialties/state";
import { CustomComponentsState } from "./custom-components/state";
import { VaccinesState } from "./vaccines/state";
import { LoginsState } from "./logins/state";
import { ICancellationReason } from "@/interfaces/global/ICancellationReason"
import CancellationReasonModule from "./cancellation-reasons"
export interface StateInterface {
  general: GeneralStoreUI;
  auth: AuthState;
  available: AvailableUI;
  centers: StateCenters;
  users: UsersState;
  medicines: MedicinesState;
  contracts: ContractsUI;
  certificates: ICertificateStateCustom;
  specialtes: SpecialtesState;
  procedures: ProceduresState;
  atentioncenter: AtentionCenterState;
  diagnostics: DiagnosticsState;
  patients: PatientsState;
  attendance: AttendanceUI;
  referenceCenters: ReferenceCentersState;
  teleexpertises: TeleexpertisesState;
  schedules: SchedulesState;
  medicalRecords: MedicalRecordsState;
  healthcareManagement: HealthcareManagementUI;
  jobList: JobListUI;
  folios: FoliosState;
  carePrograms: CareProgramsState;
  campaigns: CampaignsState;
  disabilitiesUI: DisabilityUI;
  RipsUI: RipsUI;
  unificationMedicalRecords: UnificationMedicalRecordsState;
  explanatoryNotes: ExplanatoryNotesState;
  cdas: CdasState;
  nursingNotes: NursingNotesState;
  scheduleReports: ScheduleReportsState;
  telesupports: TelesupportsState;
  indicators: IndicatorsUI;
  liquidControl: LiquidControlState;
  selectors: SelectorsState;
  resolutionReports: ResolutionReportsState;
  oxygenControl: OxygenControlState;
  triage: TriageState;
  initialAttentions: InitialAttentionsState;
  emergencies: EmergenciesState;
  indicators256: Indicators256State;
  telemedicine: TelemedicineState;
  highCostAccounts2463: HighCostAccounts2463State;
  appointmentConfirmation: AppointmentConfirmationState;
  resolution4725: ResolutionReports4725State;
  absences: AbsenceState;
  resolution2175of2015: Resolution2175of2015State;
  proceduresReport: ProceduresReportState;
  medicinesReport: MedicinesReportState;
  resolution1604of2013: Resolution1604of2013State;
  controlBoards: ControlBoardsState;
  notifications: NotificationsState;
  medicalAdministrator: MedicationAdministratorState;
  surveys: ISurveyAbsenceCustom;
  supportModule: ISupportCustom;
  advanceDirectives: AdvanceDirectivesState;
  administrators: AdministratorsState;
  preparations: PreparationsState;
  packages: PackagesState;
  photograph: PhotographsState;
  notes: NotesState;
  banks: BanksState;
  numerations: NumerationsState;
  advanceDirectiveReport: AdvanceDirectiveReportState;
  centersSelectors: CentersSelectorsState;
  telemedicineReport: TelemedicineReportState;
  specialties: SpecialtiesState;
  customComponents: CustomComponentsState;
  vaccines: VaccinesState;
  resources: ResourcesState;
  logins: LoginsState;
  cancellationReason: ICancellationReason;
}

export default createStore<StateInterface>({
  modules: {
    general: generalStoreModule,
    auth: authModule,
    available: availableModule,
    centers: centersModule,
    users: usersModule,
    medicines: medicinesModule,
    contracts: contractsModule,
    specialtes: specialtesModule,
    procedures: proceduresModule,
    atentioncenter: atentionCentersModule,
    diagnostics: diagnosticsModule,
    patients: patientsModule,
    attendance: attendanceModule,
    referenceCenters: referenceCentersModule,
    teleexpertises: teleexpertisesModule,
    schedules: schedulesModule,
    medicalRecords: medicalRecordsModule,
    healthcareManagement: healthcareManagementModule,
    jobList: jobListModule,
    folios: foliosModule,
    carePrograms: careProgramsModule,
    campaigns: campaignsModule,
    disabilities: disabilitiesModules,
    rips: ripsModule,
    unificationMedicalRecords: unificationMedicalRecordsModule,
    explanatoryNotes: explanatoryNotesModule,
    cdas: cdasModule,
    nursingNotes: nursingNotesModule,
    scheduleReports: scheduleReportsModule,
    telesupports: telesupportsModule,
    indicators: indicatorsModule,
    liquidControl: liquidControlModule,
    selectors: selectorsModule,
    resolutionReports: resolutionReportsModule,
    oxygenControl: oxygenControlModule,
    triage: triageModule,
    initialAttentions: initialAttentionsModule,
    emergencies: emergenciesModule,
    indicators256: indicators256Module,
    telemedicine: telemedicineModule,
    highCostAccounts2463: highCostAccounts2463Module,
    appointmentConfirmation: appointmentConfirmationModule,
    resolution4725: resolution4725Module,
    absences: absencesModule,
    proceduresReport: proceduresReportModule,
    resolution2175of2015: resolution2175of2015Module,
    medicinesReport: medicinesReportModule,
    controlBoards: controlBoardsModule,
    resolution1604of2013: resolution1604of2013State,
    notifications: notificationsModule,
    medicalAdministrator: medicationAdministratorModule,
    digitalSignature: digitalSignatureModule,
    timeInactivity: timeInactivityModule,
    physicalExamTemplates: physicalExamTemplatesModule,
    backgroundsTemplates: backgroundTemplate,
    prescriptionProceduresTemplates: prescriptionProceduresTemplate,
    prescriptionMedicinesTemplates: prescriptionMedicinesTemplate,
    surveys: surveysModule,
    support: supportModule,
    advanceDirectives: advanceDirectivesModule,
    administrators: administratorModule,
    preparations: preparationModule,
    certificates: certificatesModule,
    packages: packagesModule,
    photograph: photographModule,
    notes: notesModule,
    banks: banksModule,
    numerations: numerationsModule,
    centersSelectors: centersSelectorsModule,
    telemedicineReport: telemedicineReportModule,
    advanceDirectiveReport: advanceDirectiveReportModule,
    specialties: specialtiesModule,
    customComponents: customComponentsModule,
    vaccines: vaccinesModule,
    resources: resourcesModule,
    logins: loginsModule,
    cancellationReason: CancellationReasonModule,
  },
});
