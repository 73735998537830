import { ResourcesState } from "@/interfaces/global/interfaces";
import { MutationTree } from "vuex";

const mutations: MutationTree<ResourcesState> = {
  setDocumentTypes(state: ResourcesState, data: object[]) {
    state.documentTypes = data;
  },
};

export default mutations;
