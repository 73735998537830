import { ResourcesState } from "@/interfaces/global/interfaces";

function state(): ResourcesState {
  return {
    documentTypes: [],
    medicines: [],
    procedures: [],
    specialties: [],
  };
}

export default state;
