import { MedicationAdministratorState } from "@/interfaces/global/interfaces";

function state(): MedicationAdministratorState {
  return {
    medicationAdministratorList: [],
    medicalAdministratorCurrentPage: 1,
    medicalAdministratorLastPage: 1,
    medicalAdministrator: null,
    states: [
      { label: "Todos", value: "" },
      { label: "Abierta", value: "Abierta" },
      { label: "Cerrada", value: "Cerrada" },
    ],
    admission_list: ["Urgencias", "Hospitalización", "Atención Domiciliaria"],
    presentation_type_list: ["Única", "Sucesiva"],
    dosage_form_list: [
      "Cápsula",
      "Tableta",
      "Capleta",
      "Gracea",
      "Comprimido",
      "Píldora",
      "Pastilla",
      "Gránulos",
      "Pellet",
      "Polvo",
      "Supositorio",
      "Óvulo",
      "Cinta adhesiva",
      "Parche",
      "Implante",
      "Inserto",
      "Tira",
      "Goma",
      "Aerosol",
      "Solución oral",
      "Jarabe",
      "Enjuague",
      "Ducha",
      "Enema",
      "Spray",
      "Emulsión",
      "Crema",
      "Loción",
      "Espuma",
      "Suspensión",
      "Ungüento",
      "Gel",
      "Pasta",
      "Emplasto",
      "Pomada",
      "Gotas",
      "Ampolla",
      "Inhalador",
      "Dispositivo intrauterino DIU",
      "Jabón",
      "Champú",
      "Poción",
      "Tisana",
    ],
    route_administration_list: [
      "Oral",
      "Parenteral",
      "Subcutáneo",
      "Intramuscular",
      "Sonda nasogástrica",
      "Intratecal",
      "Inhalado",
      "Sublingual",
      "Nebulizado",
      "Intravenosa",
      "Tópico",
      "Gastrostomía",
      "Vaginal",
      "Ureteral",
      "Orgastrica",
      "Intravsical",
      "Ótico",
      "No aplica",
    ],
    units_mass_list: ["mcg", "mg", "g", "mL", "L", "mEq", "UI", "MNB", "und"],
    units_time_list: [
      "Minuto(s)",
      "Hora(s)",
      "Día(s)",
      "Semana(s)",
      "Mes(es)",
      "Año(s)",
      "Según respuesta al tratamiento",
    ],
    special_indicators_list: [
      "Sin indicación especial",
      "Administración en dosis única",
      "Administración inmediata",
      "Administrar en Bolo",
      "Administrar en Goteo",
      "Infusión contínua",
      "Infusión intermitente",
      "Infusión intermitente simultánea con perfusión de otra solución",
      "Microgoteo",
      "Perfusión",
    ],
    data_api: [],
    data_table: [],
    data_form: null,
  };
}

export default state;
