import {
  IData,
  IMedicine,
  IRequestResponse,
} from "@/interfaces/global/interfaces";
import { MutationTree } from "vuex";
import { Datum, MedicinesState } from "./state";

const mutation: MutationTree<MedicinesState> = {
  setMedicineById(state: MedicinesState, medicine: Datum): void {
    if (medicine) {
      const { code, description, pbs }: Datum = medicine;
      state.medicineCode = code;
      state.medicineDescription = description;
      state.pbs = !pbs ? { label: "No", value: 0 } : { label: "Si", value: 1 };
    }
  },
  clearMedicinesData(state: MedicinesState): void {
    state.medicineCode = "";
    state.medicineDescription = "";
  },

  // Medicines V.3
  setResponse(state: MedicinesState, response?: IRequestResponse) {
    if (response) {
      state.response = response;
    } else {
      state.response = { success: false, message: "" };
    }
  },
  setMedicines(state: MedicinesState, data?: IData<IMedicine[]>): void {
    if (data) {
      state.data.medicines = data.data;

      state.pages.medicines.currentPage.value = data.current_page;
      state.pages.medicines.lastPage.value = data.last_page;
    } else {
      state.data.medicines = [];
    }
  },
  setMedicine(state: MedicinesState, data?: IMedicine | null) {
    if (data !== undefined) {
      state.data.medicine = data;

      state.requests.medicine = true;
    } else {
      state.data.medicine = null;

      state.requests.medicine = false;
    }
  },
  setListMedicinesSelector(state: MedicinesState, data: object[] = []) {
    state.medicinesListSelector = data;
  },
};

export default mutation;
