import { ActionTree } from "vuex";
import { MedicinesState, Response } from "./state";
import { StateInterface } from "../index";
import { executeApi, medicinesApi } from "@/apis";
// import { MedicineFormUI } from "../../views/medicines/interfaces/medicalsUI";
import errorException from "@/functions/ErrorException";
import { medicinesApiV2 } from "@/apis/medicinesApi";

const URL_PATH: { [key: number]: string } = {
  1: "/api/settings/medicines",
  2: "/api/settings/medicines/v2",
};

const actions: ActionTree<MedicinesState, StateInterface> = {
  async changeMedicineStatusById(_, { token, id }): Promise<Response> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await medicinesApi().post(
        `/changeStatus/${id}`,
        {},
        config
      );
      const { success, message } = data;
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  // async createMedicine(
  //   _,
  //   { token, bodyRequest }: { token: string; bodyRequest: MedicineFormUI }
  // ): Promise<Response> {
  //   const request = {
  //     ...bodyRequest,
  //     pbs: bodyRequest.pbs?.value,
  //   };

  //   const config: object = token
  //     ? {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     : {};

  //   try {
  //     const { data } = await medicinesApi().post("/", request, config);
  //     const { success, message } = data;

  //     return { success, message };
  //   } catch (error) {
  //     return errorException(error);
  //   }
  // },
  async LoadMedicineById({ commit }, id): Promise<Response> {
    try {
      const { data } = await medicinesApi().get(`/${id}`);
      const { success, message, data: medicineData } = data;

      commit("setMedicineById", medicineData);
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  // async updateMedicine(
  //   _,
  //   { bodyRequest, id }: { bodyRequest: MedicineFormUI; id: string }
  // ): Promise<Response> {
  //   const request = {
  //     ...bodyRequest,
  //     pbs: bodyRequest.pbs?.value,
  //     _method: "PATCH",
  //   };

  //   try {
  //     const { data } = await medicinesApiV2().post(`/${id}`, request);
  //     const { success, message } = data;

  //     return { success, message };
  //   } catch (error) {
  //     return errorException(error);
  //   }
  // },

  // Medicines V2
  async getMedicinesV2({ commit }, data) {
    const { token, params } = data;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    };

    try {
      const { data } = await medicinesApiV2().get("", config);
      commit("setMedicines", data);
      return data;
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },

  // Medicines V.3
  async loadMedicines({ commit }, { params, version }) {
    commit("setMedicines");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(
        `${URL_PATH[version]}`,
        {
          params,
        }
      );
      const { success, message, data } = response;

      commit("setMedicines", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadMedicine({ commit }, id) {
    commit("setMedicine");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(`${URL_PATH[1]}/${id}`);
      const { success, message, data } = response;

      commit("setMedicine", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async createMedicine({ commit }, bodyRequest) {
    commit("setResponse");

    try {
      const { data } = await executeApi().post(`${URL_PATH[2]}`, bodyRequest);
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async updateMedicine({ commit }, { id, bodyRequest }) {
    commit("setResponse");

    try {
      const { data } = await executeApi().post(
        `${URL_PATH[2]}/${id}`,
        bodyRequest
      );
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
  async changeMedicineStatus({ commit }, id) {
    commit("setMedicines");
    commit("setResponse");

    try {
      const { data } = await executeApi().post(
        `${URL_PATH[1]}/changeStatus/${id}`
      );
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
  async deleteMedicine({ commit }, id) {
    commit("setMedicines");
    commit("setResponse");

    try {
      const { data } = await executeApi().delete(`${URL_PATH[1]}/${id}`);
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },

  async medicinesListWithoutPaginate({ commit }, params) {
    try {
      const { data: response } = await medicinesApi().get("/withoutPaginate");
      const { success, message, data } = response;
      commit("setListMedicinesSelector", data);
      return { success, message, data };
    } catch (error) {
      return errorException(error);
    }
  },
};

export default actions;
