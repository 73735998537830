import {
  IARL,
  ICareCenter,
  ICarePrograms,
  ICity,
  IDepartment,
  IDocumentType,
  IEPS,
  IMembership,
  IPrepaid,
  IReferenceCenter,
  IRegime,
  IRequestResponse,
  ISex,
} from "@/interfaces/global/interfaces";

export type centersRequest = "referenceCenters" | "careCenters";
export type patientRequest =
  | "eps"
  | "memberships"
  | "documentTypes"
  | "regimes"
  | "arls"
  | "prepaids"
  | "sexes"
  | "departments"
  | "cities"
  | "carePrograms"
  | "bloodTypes"
  | "nationalities"
  | "expedition_location";

export type centersValue = "referenceCenter" | "careCenter";
export type patientValue =
  | "eps"
  | "membership"
  | "documentType"
  | "regime"
  | "arl"
  | "prepaid"
  | "sex"
  | "department"
  | "city"
  | "carePrograms"
  | "bloodType"
  | "nationalities"
  | "expedition_location";
export interface SelectorsRequest {
  centers: Record<centersRequest, boolean>;
  patient: Record<patientRequest, boolean>;
}

export interface SelectorsValue {
  centers: Record<centersValue, number | null | any >;
  patient: {
    eps: number | null;
    membership: number | null;
    documentType: number | null;
    regime: number | null;
    arl: number | null;
    prepaid: number | null;
    sex: number | null;
    department: string | null;
    city: string | null;
    carePrograms: number[];
    bloodType: string | null;
    nationalities: string | null;
    expedition_location: string | null;
  };
}

export interface SelectorsResponse extends IRequestResponse {
  of: centersValue | patientValue | null | "patientResources";
}

export interface SelectorsState {
  values: SelectorsValue;
  request: SelectorsRequest;
  response: SelectorsResponse;
  centers: {
    referenceCenters: IReferenceCenter[];
    careCenters: ICareCenter[];
  };
  patient: {
    eps: IEPS[];
    memberships: IMembership[];
    documentTypes: IDocumentType[];
    regimes: IRegime[];
    arls: IARL[];
    prepaids: IPrepaid[];
    sexes: ISex[];
    departments: IDepartment[];
    cities: ICity[];
    carePrograms: ICarePrograms[];
    bloodTypes: string[];
    nationalities: string[];
    expedition_location: string[];
  };
}

function state(): SelectorsState {
  return {
    values: {
      centers: {
        referenceCenter: null,
        careCenter: null,
      },
      patient: {
        eps: null,
        membership: null,
        documentType: null,
        regime: null,
        arl: null,
        prepaid: null,
        sex: null,
        department: null,
        city: null,
        carePrograms: [],
        bloodType: null,
        nationalities: null,
        expedition_location: null,
      },
    },
    request: {
      centers: {
        referenceCenters: false,
        careCenters: false,
      },
      patient: {
        eps: false,
        memberships: false,
        documentTypes: false,
        regimes: false,
        arls: false,
        prepaids: false,
        sexes: false,
        departments: false,
        cities: false,
        carePrograms: false,
        bloodTypes: false,
        nationalities: false,
        expedition_location: false,
      },
    },
    response: {
      success: false,
      message: "",
      of: null,
    },
    centers: {
      referenceCenters: [],
      careCenters: [],
    },
    patient: {
      eps: [],
      memberships: [],
      documentTypes: [],
      regimes: [],
      arls: [],
      prepaids: [],
      sexes: [],
      departments: [],
      cities: [],
      carePrograms: [],
      bloodTypes: [],
      nationalities: [],
      expedition_location: [],
    },
  };
}

export default state;
