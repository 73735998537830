import { createApp, App as VueApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Quasar from 'quasar/src/vue-plugin.js';;
import { loadFonts } from "./plugins/webfontloader";
import quasarUserOptions from "./quasar-user-options";
import "@quasar/extras/mdi-v7/mdi-v7.css";
import "@quasar/extras/fontawesome-v6/fontawesome-v6.css";
import MainLoader from "@/components/loader/MainLoader.vue";
import ablyPlugin from "@/plugins/Echo";

// Global Styles
import "@/main.scss";

let appInstance: VueApp<Element> | null = null;

const app = createApp(App);
appInstance = app; // Guardar la instancia

// register global components
app.component("MainLoader", MainLoader);

app
  .use(Quasar, quasarUserOptions)
  .use(store)
  .use(router)
  .use(loadFonts)
  .use(ablyPlugin)
  .mount("#app");

// Función para obtener la instancia de la aplicación
export function getAppInstance(): VueApp<Element> | null {
  return appInstance;
}
