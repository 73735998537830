// interfaces
import { IGenderByTypes } from "@/interfaces/customs/Type";

export const useTypes = () => {
  const serviceType = (serviceType: number) => {
    switch (serviceType) {
      case -1:
        return "Todas las telexperticias";
      case 1:
        return "Telexperticia interactiva";
      case 2:
        return "Telexperticia no Interactivas";
      case 3:
        return "Teleapoyo Interactiva";
      case 9:
        return "Teleapoyo no interactiva";
      case 8:
        return "Telemedicina";
      case 10:
        return "Telemedicina no interactiva";
      default:
        return "Sin definir...";
    }
  };

  const responseMeetStatus = (statusMessage: string) => {
    switch (statusMessage) {
      case "Esta sesion esta cancelada":
        return {
          type: "counter/cancel",
          message: statusMessage,
        };
      case "Esta sesion esta enviada":
        return {
          type: "counter/reschedule",
          message: statusMessage,
        };

      case "Esta sesion esta realizada":
        return {
          type: "counter/success",
          message: statusMessage,
        };

      default:
        return {
          type: "counter/error",
          message: statusMessage,
        };
    }
  };

  const fullFileTypes = [
    "image/jpeg",
    "image/png",
    "image/pjpeg",
    "image/bmp",
    "image/x-windows-bmp",
    "video/avi",
    "video/msvideo",
    "video/x-msvideo",
    "audio/mpeg",
    "audio/x-mpeg",
    "audio/mp3",
    "audio/x-mp3",
    "audio/mpeg3",
    "audio/x-mpeg3",
    "audio/mpg",
    "audio/x-mpg",
    "audio/x-mpegaudio",
    "application/x-troff-msvideo",
    "application/msword",
    "application/pdf",
    "application/zsa",
    "application/dicom",
    "application/scp",
    "application/octet-stream",
    "text/plain",
    "text/xml",
    "application/x-zip-compressed",
  ];

  const genderTypes: IGenderByTypes = {
    Masculino: { min: "el hombre", caps: "El hombre" },
    Femenino: { min: "la mujer", caps: "La mujer" },

    Teleapoyo: { min: "el Teleapoyo", caps: "El Teleapoyo" },
    "Telexperticia Interactiva": {
      min: "la Telexperticia Interactiva",
      caps: "La Telexperticia Interactiva",
    },
    "Telemedicina Interactiva": {
      min: "la Telemedicina Interactiva",
      caps: "La Telemedicina Interactiva",
    },
    "Teleapoyo Interactivo": {
      min: "el Teleapoyo Interactivo",
      caps: "El Teleapoyo Interactivo",
    },

    "Telemedicina no Interactiva": {
      min: "la Telemedicina no Interactiva",
      caps: "La Telemedicina no Interactiva",
    },
    "Cirugía": { min: "la Cirugía", caps: "La Cirugía" },
    Consulta: { min: "la Consulta", caps: "La Consulta" },
    Cita: { min: "la Cita", caps: "La Cita" },
    Sesión: { min: "la Sesión", caps: "La Sesión" },
  };

  const genderRoleTypes: IGenderByTypes = {
    "Masculino Médico": { min: "el médico", caps: "El médico" },
    "Femenino Médico": { min: "la médica", caps: "La médica" },
    "Masculino Auxiliar": { min: "el auxiliar", caps: "El auxiliar" },
    "Femenino Auxiliar": { min: "la auxiliar", caps: "La auxiliar" },
    "Masculino Paciente": { min: "el paciente", caps: "El paciente" },
    "Femenino Paciente": { min: "la paciente", caps: "La paciente" },
    "Masculino Doctor": { min: "el doctor", caps: "El doctor" },
    "Femenino Doctor": { min: "la doctora", caps: "La doctora" },
    "Masculino Acompañante": { min: "el acompañante", caps: "El acompañante" },
    "Femenino Acompañante": { min: "la acompañante", caps: "La acompañante" },
  };

  const fileTypes = (fileType: string) => {
    switch (fileType) {
      case "image/jpeg":
        return "JPG";
      case "image/png":
        return "png";
      case "application/x-zip-compressed":
        return "ZIP";
      case "application/pdf":
        return "PDF";
      case "text/plain":
        return "TXT";
      default:
        return "?";
    }
  };

  return {
    serviceType,
    responseMeetStatus,
    fileTypes,
    fullFileTypes,
    genderTypes,
    genderRoleTypes,
  };
};
