import { computed } from "vue";
import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { DataList, TelexpertisesList, Tab } from "@/store/teleexpertises/state";
import { IAllResource, IPatient } from "@/interfaces/global/interfaces";
import { IDataTable } from "@/interfaces/customs/interface";

export const useTeleexpertisesStore = () => {
  const store = useStore<StateInterface>();

  return {
    // State
    tabTelexpertice: computed<Tab>(() => {
      return store.state.teleexpertises.tab;
    }),
    teleexpertisesList: computed<DataList[]>(() => {
      return store.state.teleexpertises.teleexpertisesList;
    }),
    telexpertiseResource: computed<IAllResource>(
      () => store.state.teleexpertises.resourcesTelexpertises
    ),
    backgroundsHistoryList: computed<Array<any>>(
      () => store.state.teleexpertises.backgroundsHistory
    ),
    clinicalLaboratoryHistoryList: computed<Array<any>>(
      () => store.state.teleexpertises.clinicalLaboratoryHistory
    ),
    medicalExamsHistoryList: computed<Array<any>>(
      () => store.state.teleexpertises.medicalExamsHistory
    ),
    diagnosticHistoryList: computed<IDataTable>(
      () => store.state.teleexpertises.diagnosticHistory
    ),
    vaccineHistoryList: computed<IDataTable>(
      () => store.state.teleexpertises.vaccineHistory
    ),
    medicineHistoryList: computed<IDataTable>(
      () => store.state.teleexpertises.medicineHistory
    ),
    procedureHistoryList: computed<IDataTable>(
      () => store.state.teleexpertises.procedureHistory
    ),

    // State Pagination Vaccination
    vaccinationCurrentPage: computed<any>(
      () => store.state.teleexpertises.vaccinationCurrentPage
    ),
    vaccinationLastPage: computed<any>(
      () => store.state.teleexpertises.vaccinationLastPage
    ),

    // data Response Telexpertice
    dataResponseTelexpertice: computed<any>(
      () => store.state.teleexpertises.dataResponseTelexpertice
    ),
    dataResponseTableTelexpertice: computed<any>(
      () => store.state.teleexpertises.dataResponseTelexperticeTable
    ),
    // State Pagination Medicines Historial
    dataTableHistoryMedicinesPatient: computed<any>(
      () => store.state.teleexpertises.dataTableHistoryMedicinesPatient
    ),
    dataTableHistoryMedicinesPatientCurrentPage: computed<any>(
      () =>
        store.state.teleexpertises.dataTableHistoryProceduresPatientCurrentPage
    ),
    dataTableHistoryMedicinesPatientLastPage: computed<any>(
      () => store.state.teleexpertises.dataTableHistoryProceduresPatientLastPage
    ),

    // State Pagination Procedures Historial
    dataTableHistoryProceduresPatient: computed<any>(
      () => store.state.teleexpertises.dataTableHistoryProceduresPatient
    ),
    dataTableHistoryProceduresPatientCurrentPage: computed<any>(
      () =>
        store.state.teleexpertises.dataTableHistoryProceduresPatientCurrentPage
    ),
    dataTableHistoryProceduresPatientLastPage: computed<any>(
      () => store.state.teleexpertises.dataTableHistoryProceduresPatientLastPage
    ),

    // State Pagination Background History
    backgroundHistoryCurrentPage: computed<any>(
      () => store.state.teleexpertises.backgroundsHistoryCurrentPage
    ),
    backgroundHistoryLastPage: computed<any>(
      () => store.state.teleexpertises.backgroundsHistoryLastPage
    ),

    // State Pagination Vaccine History
    vaccineHistoryCurrentPage: computed<any>(
      () => store.state.teleexpertises.vaccineHistoryCurrentPage
    ),
    vaccineHistoryLastPage: computed<any>(
      () => store.state.teleexpertises.vaccineHistoryLastPage
    ),

    // State Pagination Medicine History
    medicineHistoryCurrentPage: computed<any>(
      () => store.state.teleexpertises.medicineHistoryCurrentPage
    ),
    medicineHistoryLastPage: computed<any>(
      () => store.state.teleexpertises.medicineHistoryLastPage
    ),

    // State Pagination Medicine History
    procedureHistoryCurrentPage: computed<any>(
      () => store.state.teleexpertises.procedureHistoryCurrentPage
    ),
    procedureHistoryLastPage: computed<any>(
      () => store.state.teleexpertises.procedureHistoryLastPage
    ),

    // State Pagination Background
    backgroundCurrentPage: computed<any>(
      () => store.state.teleexpertises.backgroundCurrentPage
    ),
    backgroundLastPage: computed<any>(
      () => store.state.teleexpertises.backgroundLastPage
    ),

    // State Pagination Clinical Laboratory
    clinicalLaboratoryCurrentPage: computed<any>(
      () => store.state.teleexpertises.clinicalLaboratoryCurrentPage
    ),
    clinicalLaboratoryLastPage: computed<any>(
      () => store.state.teleexpertises.clinicalLaboratoryLastPage
    ),

    // State Pagination Clinical Laboratory History
    clinicalLaboratoryHistoryCurrentPage: computed<any>(
      () => store.state.teleexpertises.clinicalLaboratoryHistoryCurrentPage
    ),
    clinicalLaboratoryHistoryLastPage: computed<any>(
      () => store.state.teleexpertises.clinicalLaboratoryHistoryLastPage
    ),

    // State Pagination Medical Exams
    medicalExamsCurrentPage: computed<any>(
      () => store.state.teleexpertises.medicalExamsCurrentPage
    ),
    medicalExamsLastPage: computed<any>(
      () => store.state.teleexpertises.medicalExamsLastPage
    ),

    // State Pagination Medical Exams History
    medicalExamsHistoryCurrentPage: computed<any>(
      () => store.state.teleexpertises.medicalExamsHistoryCurrentPage
    ),
    medicalExamsHistoryLastPage: computed<any>(
      () => store.state.teleexpertises.medicalExamsHistoryLastPage
    ),

    // State Pagination Diagnostic
    diagnosticCurrentPage: computed<any>(
      () => store.state.teleexpertises.diagnosticCurrentPage
    ),
    diagnosticLastPage: computed<any>(
      () => store.state.teleexpertises.diagnosticLastPage
    ),

    // State Pagination Diagnostic History
    diagnosticHistoryCurrentPage: computed<any>(
      () => store.state.teleexpertises.diagnosticHistoryCurrentPage
    ),
    diagnosticHistoryLastPage: computed<any>(
      () => store.state.teleexpertises.diagnosticHistoryLastPage
    ),

    getDiagnosticData: computed<any>(
      () => store.state.teleexpertises.dataDiagnostic
    ),

    // State Pagination Documents
    documentCurrentPage: computed<any>(
      () => store.state.teleexpertises.documentsCurrentPage
    ),
    documentcLastPage: computed<any>(
      () => store.state.teleexpertises.documentsLastPage
    ),

    jsonDataGraphic: computed<any>(
      () => store.state.teleexpertises.jsonDataGraphic
    ),

    /**Peso para la edad edad - Nuevo*/
    weightForAgeGirl: computed<any>(
      () => store.state.teleexpertises.weightForAgeGirl
    ),

    weightForAgeBoy: computed<any>(
      () => store.state.teleexpertises.weightForAgeBoy
    ),
    /**Peso para la edad edad - Nuevo* --------------------------------------------------------*/

    /**Talla para la edad edad - Nuevo*/
    sizeForAgeGirl: computed<any>(
      () => store.state.teleexpertises.sizeForAgeGirl
    ),

    sizeForAgeBoy: computed<any>(
      () => store.state.teleexpertises.sizeForAgeBoy
    ),
    /**Talla para la edad edad - Nuevo* --------------------------------------------------------*/

    /**IMC para la edad edad - Nuevo*/
    imcForAgeGirl: computed<any>(
      () => store.state.teleexpertises.imcForAgeGirl
    ),

    imcForAgeBoy: computed<any>(() => store.state.teleexpertises.imcForAgeBoy),
    /**IMC para la edad edad - Nuevo* --------------------------------------------------------*/

    /**Perímetro Cefálico para la edad edad - Nuevo*/
    headCircunferenceForAgeGirl: computed<any>(
      () => store.state.teleexpertises.headCircunferenceForAgeGirl
    ),

    headCircunferenceForAgeBoy: computed<any>(
      () => store.state.teleexpertises.headCircunferenceForAgeBoy
    ),
    /**Perímetro Cefálico para la edad edad - Nuevo* --------------------------------------------------------*/

    specialtySelected: computed<any>(
      () => store.state.teleexpertises.specialtySelected
    ),

    queryTypeSelected: computed<any>(
      () => store.state.teleexpertises.queryTypeSelected
    ),

    documentPatient: computed<any>(
      () => store.state.teleexpertises.documentPatient
    ),
    documentResponseCurrentPage: computed<any>(
      () => store.state.teleexpertises.documentsResponseCurrentPage
    ),
    documentResponseLastPage: computed<any>(
      () => store.state.teleexpertises.documentsResponseLastPage
    ),

    managementsPlan: computed<any>(() => store.state.teleexpertises.dataResponseTelexpertice),

    medicines: computed<any>(() => store.state.teleexpertises.dataTabMedicines),

    procedures: computed<any>(
      () => store.state.teleexpertises.dataTabProcedures
    ),

    documentsResponseCurrentPage: computed<any>(
      () => store.state.teleexpertises.documentsResponseCurrentPage
    ),
    documentsResponseLastPage: computed<any>(
      () => store.state.teleexpertises.documentsResponseLastPage
    ),
    medicinesCurrentPage: computed<any>(
      () => store.state.teleexpertises.medicinesCurrentPage
    ),
    medicinesLastPage: computed<any>(
      () => store.state.teleexpertises.medicinesLastPage
    ),
    proceduresCurrentPage: computed<any>(
      () => store.state.teleexpertises.proceduresCurrentPage
    ),
    proceduresLastPage: computed<any>(
      () => store.state.teleexpertises.proceduresLastPage
    ),
    telexperticesResource: computed<any>(
      () => store.state.teleexpertises.resourcesTelexpertises
    ),
    telexperticeById: computed<any>(
      () => store.state.teleexpertises.telexperticeById
    ),

    // Scales
    scales: computed<any>(() => store.state.teleexpertises.dataScales),
    interpretations: computed<any>(
      () => store.state.teleexpertises.dataInterpretations
    ),
    optionScales: computed<any>(() => store.state.teleexpertises.optionScales),

    // Telexpetises List by Patient
    telexpertisesListWP: computed<TelexpertisesList[]>(
      () => store.state.teleexpertises.telexpertisesListWP
    ),

    diagnosticsTypes: computed<string[]>(
      () => store.state.teleexpertises.diagnosticsTypes
    ),

    typification: computed<string[]>(
      () => store.state.teleexpertises.typification
    ),

    // Getters ################################################################################################################################

    teleexpertisesCurrentPage: computed<number>(
      () => store.getters["teleexpertises/getTeleexpertisesCurrentPage"]
    ),
    teleexpertisesLastPage: computed<number>(
      () => store.getters["teleexpertises/getTeleexpertisesLastPage"]
    ),

    /** Getter from telexpertise */

    /**Patient Detail ***************************************/
    getPatientDetails: computed<IPatient>(
      () => store.getters["teleexpertises/getPatientDetails"]
    ),

    /**Reason Consultation */
    getReasonConsultation: computed<{
      current_illness: string;
      reason_for_consultation: string;
      query_type?: string;
    } | null>(() => store.getters["teleexpertises/getReasonConsultation"]),

    /**Nursing Records */
    getNursingRecords: computed<any>(
      () => store.getters["teleexpertises/getNursingRecord"]
    ),

    /**Scales */
    getScales: computed<any>(() => store.getters["teleexpertises/getScales"]),

    /**Vaccination */
    getVaccination: computed<any>(
      () => store.getters["teleexpertises/getVaccination"]
    ),
    getDataTableVaccination: computed<any>(
      () => store.getters["teleexpertises/getDataTableVaccination"]
    ),

    /**Background */
    getDataTableBackground: computed<any>(
      () => store.getters["teleexpertises/getDataTableBackground"]
    ),
    getBackgrounds: computed<any>(
      () => store.getters["teleexpertises/getBackgrounds"]
    ),

    /**Clinical Laboratory */
    getDataTableClinicalLaboratory: computed<any>(
      () => store.getters["teleexpertises/getDataTableClinicalLaboratory"]
    ),
    getClinicalLaboratory: computed<any>(
      () => store.getters["teleexpertises/getClinicalLaboratory"]
    ),

    /**Medical Exams */
    getDataTableMedicalExams: computed<any>(
      () => store.getters["teleexpertises/getDataTableMedicalExams"]
    ),
    getMedicalExams: computed<any>(
      () => store.getters["teleexpertises/getMedicalExams"]
    ),
    getMedicalExamsDocuments: computed<any>(
      () => store.getters["teleexpertises/getMedicalExamsDocuments"]
    ),
    getClinicalLaboratoriesDocuments: computed<any>(
      () => store.getters["teleexpertises/getClinicalLaboratoriesDocuments"]
    ),

    /**Vital Signs */
    getVitalSigns: computed<any>(
      () => store.getters["teleexpertises/getVitalSigns"]
    ),

    /**Vital Signs Nursing Notes */
    getVitalSignsNursingNotes: computed<any>(
      () => store.getters["teleexpertises/getVitalSignsNursingNotes"]
    ),

    /**Physical Examns */
    getPhysicalExams: computed<any>(
      () => store.getters["teleexpertises/getPhysicalExams"]
    ),

    /**Diagnostic */
    getDataTableDiagnostic: computed<IDataTable>(
      () => store.getters["teleexpertises/getDataTableDiagnostic"]
    ),
    getDiagnostic: computed<any>(
      () => store.getters["teleexpertises/getDiagnostic"]
    ),

    /** Documents */
    getDataTableDocuments: computed<any>(
      () => store.getters["teleexpertises/getDataTableDocuments"]
    ),
    getDocuments: computed<any>(
      () => store.getters["teleexpertises/getDocuments"]
    ),

    /** Attached Documents */
    getDataTableAttachedDocuments: computed<any>(
      () => store.getters["teleexpertises/getDataTableAttachedDocuments"]
    ),
    getAttachedDocuments: computed<any>(
      () => store.getters["teleexpertises/getDataAttachedDocuments"]
    ),
    /** Requests */
    getRequests: computed<any>(
      () => store.getters["teleexpertises/getRequests"]
    ),

    /** Response */
    getDataResponse: computed<any>(
      () => store.getters["teleexpertises/getDataResponse"]
    ),

    /** Documents Response */
    getDocumentsResponse: computed<any>(
      () => store.getters["teleexpertises/getDocumentsResponse"]
    ),
    getDataTableDocumentsResponse: computed<any>(
      () => store.getters["teleexpertises/getDataTableDocumentsResponse"]
    ),

    /** TechnicalAnnex */
    getTechnicalAnnex: computed<any>(
      () => store.getters["teleexpertises/getTechnicalAnnex"]
    ),

    /** Getter from telexpertise ***************************************/

    // Actions #############################################################################################################################

    getTeleexpertises: (token: string, page: number, status: string) =>
      store.dispatch("teleexpertises/getTeleexpertises", {
        token,
        page,
        status,
      }),
    getListTelexpertiseV2: (page: number, filters: object) =>
      store.dispatch("teleexpertises/getListTelexpertiseV2", {
        page,
        filters,
      }),
    changeTeleexpertiseStatusById: (token: string, id: number) =>
      store.dispatch("teleexpertises/changeTeleexpertiseStatusById", {
        token,
        id,
      }),
    createTeleexpertise: (
      token: string,
      bodyRequest: any,
      medicalExamsDocuments: any,
      clinicalLaboratoriesDocuments: any,
      documents: any,
      signature: File
    ) =>
      store.dispatch("teleexpertises/createTeleexpertise", {
        token,
        bodyRequest,
        medicalExamsDocuments,
        clinicalLaboratoriesDocuments,
        documents,
        signature,
      }),
    responseTeleexpertise: (token: string, bodyRequest: any, documents: any) =>
      store.dispatch("teleexpertises/responseTeleexpertise", {
        token,
        bodyRequest,
        documents,
      }),

    getTeleexpertiseById: (token: string, id: any) =>
      store.dispatch("teleexpertises/getTeleexpertiseById", { token, id }),

    updateTeleexpertise: (token: string, id: number, formData: object) =>
      store.dispatch("teleexpertises/updateTeleexpertise", {
        token,
        id,
        formData,
      }),

    getAllResources: (filters: string | null = null) =>
      store.dispatch("teleexpertises/getAllResources", { filters }),

    getBackgroundHistory: (
      token: string | null,
      patientID: number | string,
      page: number
    ) =>
      store.dispatch("teleexpertises/getBackgroundHistory", {
        token,
        patientID,
        page,
      }),

    getClinicalLaboratoryHistory: (
      token: string | null,
      patientID: number,
      page: number
    ) =>
      store.dispatch("teleexpertises/getClinicalLaboratoryHistory", {
        token,
        patientID,
        page,
      }),

    getMedicalExamsHistory: (
      token: string | null,
      patientID: number,
      page: number
    ) =>
      store.dispatch("teleexpertises/getMedicalExamsHistory", {
        token,
        patientID,
        page,
      }),

    getDiagnosticHistory: (
      token: string | null,
      patientID: number | null | undefined,
      page: number,
      filter: string | null = null
    ) =>
      store.dispatch("teleexpertises/getDiagnosticHistory", {
        token,
        patientID,
        page,
        filter,
      }),

    getVaccinesHistory: (patientID: number | null | undefined, page: number) =>
      store.dispatch("teleexpertises/getVaccinesHistory", {
        patientID,
        page,
      }),

    getMedicinesHistory: (patientID: number | null | undefined, page: number) =>
      store.dispatch("teleexpertises/getMedicineHistory", {
        patientID,
        page,
      }),

    getProceduresHistory: (
      patientID: number | null | undefined,
      page: number
    ) =>
      store.dispatch("teleexpertises/getProcedureHistory", {
        patientID,
        page,
      }),

    getTelexperticeUtilEntityByIdPatient: (
      token: string,
      patientID: number,
      entity: string,
      page: number
    ) =>
      store.dispatch("teleexpertises/getTelexperticeUtilEntityByIdPatient", {
        token,
        patientID,
        entity,
        page,
      }),

    getEntityRespondTelexpertise: (
      token: string,
      entity: string,
      id: string | string[],
      page: number
    ) =>
      store.dispatch("teleexpertises/getEntityRespondTelexpertise", {
        token,
        entity,
        id,
        page,
      }),

    // Scales
    calculateScale: (token: string, formData: object) =>
      store.dispatch("teleexpertises/calculateScale", {
        token,
        formData,
      }),

    getOptionsScales: (token: string, filterScales: string | null) =>
      store.dispatch("teleexpertises/getOptionsScales", {
        token,
        filterScales,
      }),

    // Get Telexpertises List by Patient
    getTelexpertisesByPatient: (id: number, type: string) =>
      store.dispatch("teleexpertises/getTelexpertisesByPatient", { id, type }),

    getQuestions: () => store.dispatch("teleexpertises/getQuestions"),

    setQualification: (dataRequest: object, telexpertiseId: string | number) =>
      store.dispatch("teleexpertises/setQualification", {
        dataRequest,
        telexpertiseId,
      }),

    // MUTATTION ###########################################################################################################################

    // Mutation Telexpertise -----------------------------------------------------------

    setTab: (tab: any) =>
    store.commit("teleexpertises/setTab", tab),
    
    /**Patient Detail */
    setPatientDetails: (details: any) =>
      store.commit("teleexpertises/setPatientDetails", details),

    /**Reason Consultation */
    setReasonConsultation: (reasonConsultations: any) =>
      store.commit("teleexpertises/setReasonConsultation", reasonConsultations),

    /**Nursing Records */
    setNursingRecords: (nursingRecords: any) =>
      store.commit("teleexpertises/setNursingRecords", nursingRecords),

    /**Scales */
    setScales: (scales: any) =>
      store.commit("teleexpertises/setScales", scales),

    setInterpretations: (scales: any) =>
      store.commit("teleexpertises/setInterpretations", scales),

    setScalesOptions: (scales: any) =>
      store.commit("teleexpertises/setScalesOptions", scales),

    /**Vaccination */
    setVaccination: (vaccines: any) =>
      store.commit("teleexpertises/setVaccination", vaccines),

    setDataTableVaccination: (dataTable: any) =>
      store.commit("teleexpertises/setDataTableVaccination", dataTable),

    /**Backgrounds */
    setDataTableBackground: (dataTable: any) =>
      store.commit("teleexpertises/setDataTableBackground", dataTable),

    setBackgrounds: (backgrounds: any) =>
      store.commit("teleexpertises/setBackgrounds", backgrounds),

    /**Clinical Laboratory */
    setDataTableClinicalLaboratory: (dataTable: any) =>
      store.commit("teleexpertises/setDataTableClinicalLaboratory", dataTable),

    setClinicalLaboratory: (clinicalLaboratory: any) =>
      store.commit("teleexpertises/setClinicalLaboratory", clinicalLaboratory),

    setClinicalLaboratoryPagination: (pages: any) =>
      store.commit("teleexpertises/setClinicalLaboratoryPagination", pages),

    /**Medical Exams */
    setDataTableMedicalExams: (dataTable: any) =>
      store.commit("teleexpertises/setDataTableMedicalExams", dataTable),

    setMedicalExams: (medicalExams: any) =>
      store.commit("teleexpertises/setMedicalExams", medicalExams),

    setMedicalExamsDocuments: (medicalExamsDocuments: any) =>
      store.commit(
        "teleexpertises/setMedicalExamsDocuments",
        medicalExamsDocuments
      ),

    setClinicalLaboratoriesDocuments: (clinicalLaboratoriesDocuments: any) =>
      store.commit(
        "teleexpertises/setClinicalLaboratoriesDocuments",
        clinicalLaboratoriesDocuments
      ),

    /**Vital Signs */
    setVitalSigns: (vitalSigns: any) =>
      store.commit("teleexpertises/setVitalSigns", vitalSigns),

    /**Vital Signs Nursing Notes */
    setVitalSignsNursingNotes: (vitalSigns: any) =>
      store.commit("teleexpertises/setVitalSignsNursingNotes", vitalSigns),

    /**Physical Exams */
    setPhysicalExams: (physicalExams: any) =>
      store.commit("teleexpertises/setPhysicalExams", physicalExams),

    /**Diagnostic */
    setDataTableDiagnostic: (dataTable: any) =>
      store.commit("teleexpertises/setDataTableDiagnostic", dataTable),

    setDiagnostic: (diagnostic: any) =>
      store.commit("teleexpertises/setDiagnostic", diagnostic),

    /**Documents */
    setDataTableDocuments: (dataTable: any) =>
      store.commit("teleexpertises/setDataTableDocuments", dataTable),

    setDocuments: (documents: any) => {
      store.commit("teleexpertises/setDocuments", documents);
    },

    /**Requests */
    setRequests: (request: any) =>
      store.commit("teleexpertises/setRequest", request),

    /**Specialty */
    setSpecialte: (request: any) =>
      store.commit("teleexpertises/setSpecialte", request),

    /**Query Type */
    setQueryType: (request: any) =>
      store.commit("teleexpertises/setQueryType", request),

    /**Document Patient */
    setDocumentPatient: (request: any) =>
      store.commit("teleexpertises/setDocumentPatient", request),

    /**Response */
    setResponse: (request: any) =>
      store.commit("teleexpertises/setResponse", request),

    /**Medicines */
    setMedicines: (request: any) =>
      store.commit("teleexpertises/setTabMedicines", request),

    /**Procedures */
    setProcedures: (request: any) =>
      store.commit("teleexpertises/setTabProcedures", request),

    /**Documents Response */
    setDataTableDocumentsResponse: (dataTable: any) =>
      store.commit("teleexpertises/setDataTableDocumentsResponse", dataTable),

    setDocumentsResponse: (documentsResponse: any) =>
      store.commit("teleexpertises/setDocumentsResponse", documentsResponse),

    setTechnicalAnnex: (technicalAnnex: any) =>
      store.commit("teleexpertises/setTechnicalAnnex", technicalAnnex),

    /**Attached Documents */
    setDataTableAttachedDocuments: (dataTable: any) =>
      store.commit("teleexpertises/setDataTableAttachedDocuments", dataTable),

    setAttachedDocuments: (documents: any) => {
      store.commit("teleexpertises/setAttachedDocuments", documents);
    },
  };
};
