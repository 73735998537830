import { GetterTree } from "vuex";
import { MedicinesState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<MedicinesState, StateInterface> = {
  getMedicineCode(state) {
    return state.medicineCode;
  },
  getMedicineDescription(state) {
    return state.medicineDescription;
  },

  getPbsValue(state) {
    return state.pbs;
  },

  // Medicines V.3
  getMedicinesList(state) {
    return state.data.medicines.length >= 1
      ? state.data.medicines.map(
          ({
            id,
            code,
            description,
            pbs,
            reference_center,
            care_center,
            status,
            default: isDefault,
          }) => ({
            id,
            code,
            description,
            pbs: pbs ? "Si" : "No",
            referenceCenter: reference_center?.name,
            careCenter: care_center?.name,
            status,
            isDefault,
          })
        )
      : [];
  },
  getMedicinesEdit(state) {
    if (state.data.medicine) {
      const {
        care_center_id: careCenter,
        code,
        description,
        pbs,
        reference_center_id: referenceCenter,
      } = state.data.medicine;

      return {
        careCenter,
        code,
        description,
        pbs: pbs ? 1 : 0,
        referenceCenter,
      };
    }

    return null;
  },
};

export default getters;
